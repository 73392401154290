//Variable
@import 'variable/variable';

//Typography
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import 'fonts/fonts';
@import 'fonts/typography';

// Mixin
@import 'mixins/mixin';

// Base
@import 'base/base';

//Scroll
@import 'scroll/scroll';

//Components
@import 'components/preloader';
@import 'components/utilities';
@import 'components/animation';
@import 'components/wheel';
@import 'components/navbar';
@import 'components/work-grid';

//Sections
@import 'sections/main';
@import 'sections/about';
@import 'sections/section-marquee';
@import 'sections/works';
@import 'sections/contact-us';
@import 'sections/contact-page';
@import 'sections/services';